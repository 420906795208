$( document ).ready(function() {

	//prevent from mistakenly clicking on link when scrolling

	var disable_click_flag = false;

	$(window).scroll(function() {
		disable_click_flag = true;

		clearTimeout($.data(this, 'scrollTimer'));

		$.data(this, 'scrollTimer', setTimeout(function() {
			disable_click_flag = false;
		}, 250));
	});

	$("body").on("click", ".nfol-link--overlay", function(e) {
		if( disable_click_flag ){
			e.preventDefault();
		}
	});

	// Init Fancybox Gallery

	$('[data-fancybox="image-gallery"]').fancybox({
		buttons : [
			'close'
		]
	});

	//Show first Gallery Image when clicking on see all

	$("#init-gallery").on("click", function(e){
		e.preventDefault();
		$(".nfol-gallery__first-image").click();
	});


	// Anchor links

	$(document).on("click", ".nfol-section-menu__item", function(e){
		e.preventDefault();
		$('html, body').animate({
			scrollTop: $( $(this).attr('href') ).offset().top
		}, 500);
	});

	$('.nfol-flickity-slider').flickity({
		pageDots: false,
		contain: true,
		cellAlign: 'center',
		wrapAround: true,
	});

	$('.nfol-flickity-slider--logos').flickity({
		pageDots: false,
		contain: true,
		cellAlign: 'center',
		wrapAround: true,
		prevNextButtons: false,
		autoPlay: true
	});

	var flkty = null;
	var navWidth = 160;

	function setupNavigationSlider(){

		var mainContainer = $('.nfol-slider--section-menu');
		var sliderContainer = $('.nfol-section-menu');

		if (mainContainer.length > 0) {

			if (sliderContainer.outerWidth() < navWidth) {

				//slider
				flkty = new Flickity(mainContainer[0], {
					cellAlign: 'left',
					autoPlay: false,
					pageDots: false,
					contain: true
				});

			} else {

				if (flkty) {
					flkty.destroy();
					flkty = null;
				}

			}

		}

	}

	function attachNavigation() {

		//nav elements size
		$('.nfol-section-menu .nfol-section-menu__item').each(function() {
			navWidth += $(this).outerWidth();
		});

		setupNavigationSlider();

		$(window).resize(function() {
			setupNavigationSlider();
			if($(window).width() > 992 ){
				$("html, body").removeClass("no-scroll");
				$(".navbar-collapse").removeClass("in");
				$(".navbar-toggle").addClass("collapsed");
			}
		});

		var navToggle = $(".navbar-toggle");
		navToggle.on("click", function(){
			$("html, body").toggleClass("no-scroll");
		});

	}

	attachNavigation();

	// Google Maps

	var mapElt = document.querySelector('.find-us__map');

	function initMap() {
		var cuisinesFolschette = {lat: 49.556626, lng: 6.107257};

		var myStyles =[
			{
				featureType: "poi",
				elementType: "labels",
				stylers: [
					{ visibility: "off" }
				]
			}
		];

		var mapOpts = {
			center: cuisinesFolschette,
			zoom: 17,
			styles: myStyles,
			fullscreenControl: false
		}
		var map = new google.maps.Map(mapElt, mapOpts);

		var size = [45,45];

		var marker = new google.maps.Marker({
			position: cuisinesFolschette,
			map: map,
			icon: cutsomMarker,
			clickable: true

		});

		var cutsomMarker = marker.setIcon(({
			url: "/media/nfolschette-marker.png",
			size: new google.maps.Size(size[0] * 2, size[1] * 2),
			origin: new google.maps.Point(0, 0),
			scaledSize: new google.maps.Size(size[0], size[1]),
			anchor: new google.maps.Point(size[0] / 2, size[1] / 2)
		}));

	}

	// Check if map element exists before init

	if (typeof(mapElt) != 'undefined' && mapElt != null) {
		initMap();
	}

	// init headroom

	if ($('.nfol-header').length > 0) {

		let myElement = document.querySelector(".nfol-header");
		// construct an instance of Headroom, passing the element
		let reactsToHeadroom = document.querySelector("[data-reacts-to-headroom]") || {};
		let headroom  = new Headroom(myElement, {
			offset: 100,
			onPin : function() {
				console.log("blob");
				reactsToHeadroom.style = `top: ${myElement.clientHeight - 1}px`;
			},
			// callback when unpinned, `this` is headroom object
			onUnpin : function() {
				reactsToHeadroom.style = `top: 0`;
			}
		});
		// initialise
		headroom.init();

	}

	// Bind banner click events
	
	function writeCookie(aName,aValue,aDays){

		var expires = "";
		if (aDays) {
			var date = new Date();
			date.setTime(date.getTime()+(aDays*24*60*60*1000));
			expires = "; expires="+date.toGMTString();
		}
		document.cookie = aName+"="+aValue+expires+"; path=/";
	}

	function readCookie(aName){
		var nameEQ = aName + "=";
		var ca = document.cookie.split(';');
		for(var i=0;i < ca.length;i++) {
			var c = ca[i];
			while (c.charAt(0)==' ') c = c.substring(1,c.length);
			if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
		}
		return null;
	}

	var cookieInvitationValue = 'invitationClosed';
	var cookieDisclaimerValue = 'cookieDisclaimerAccepted';


	$('.confirmation-close').on('click', function(e) {
		e.preventDefault();

		writeCookie(cookieInvitationValue, true, 364); // 1 year
		$('.banner-bottom .banner-40').remove();

	});

	if (readCookie(cookieInvitationValue) == null) {
		$('.banner-bottom .banner-40').removeClass('hidden');
	}

	$('#dismiss-legal-notice').on('click', function(e) {
		e.preventDefault();

		writeCookie(cookieDisclaimerValue, true, 364); // 1 year
		$('.banner-bottom .banner-legal-notice').remove();

	});

	if (readCookie(cookieDisclaimerValue) == null) {
		$('.banner-bottom .banner-legal-notice').removeClass('hidden');
	}

});